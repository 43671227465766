import { Box, Button, Checkbox, colors, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import ApiBase from "../utilities/api/ApiBase";
import { links } from "../utilities/api/links";
import AlertTemplate from "./AlertTemplate";
import TitleBox from "./TitleBox";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { BakiyePie } from "./BakiyePie";
import IslemTablosu from "./IslemTablosu";
import IslemTipleriSelectBox from "./IslemTipleriSelectBox";
import BakiyeAktarmaForm from "./BakiyeAktarmaForm";
import AlSatForm from "./AlSatForm";
import { message } from "antd";
import UzmandataLogTable from "./UzmandataLogTable";
import MusterilerSelectBox from "./MusterilerSelectBox";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const HomePage: React.FC = () => {
	const [tvUsername, setTvUsername] = useState<string>()
	const [uuid, setUuid] = useState<string>()
	const [email, setEmail] = useState<string>()
	const [choosenUser, setChoosenUser] = useState<any>(0)
	const [password, setPassword] = useState<string>()
	const [otpCode, setOtpCode] = useState<string>()
	const [araciKurumLink, setAraciKurumLink] = useState<any>(null)
	const [errorData, setErrorData] = useState<any>()
	const [users, setUsers] = useState<any>([])
	const [islemListType, setIslemListType] = useState<string>("hisse_gerceklesen_emirler")
	const [islemList, setIslemList] = useState<any>({ "hisse_portfoy": [], "hisse_gerceklesen_emirler": [], "viop_gerceklesen_emirler": [], "viop_portfoy": [] })
	const [overallTL, setOverallTL] = useState<any>([{ "id": 0, "value": 100, "label": "Yenileyin!" }])
	const [overallUSD, setOverallUSD] = useState<any>([{ "id": 0, "value": 100, "label": "Yenileyin!" }])
	const [hisseMenkulList, setHisseMenkulList] = useState<any>([{ "no": 0, "code": "Sembolleri güncelleyin!" }])
	const [clickedSymbol, setClickedSymbol] = useState<string>()
	const [selectedKurum, setSelectedKurum] = useState<string>('uzmandata');
	const [otpWrong, setOTPWrong] = useState<boolean>(false)
	const [logs, setLogs] = useState<any>([])


	const handleKurumChange = (event: SelectChangeEvent) => {
		if (event.target.value == "uzmandata") {
			getLogs()
		}
		setSelectedKurum(event.target.value as any);
	}

	useEffect(() => {
		getLogs()
	}, [choosenUser])

	const getLogs = () => {
		if (!(password == null || password == undefined || email == null || email == undefined)) {
			let url = `${links.getLogs}`
			ApiBase.Post({
				place: 'HomePage - getLogs',
				url: url,
				body: {
					email: email,
					password: password,
					choosenUser: choosenUser
				},
				successFunction: (res: any) => {
					if (res.code == 1) {
						// let data = {
						// 	alert: { type: "success", closable: true },
						// 	message: "Basariyla loglar cekildi.",
						// 	showIcon: true
						// }
						setErrorData(null)
						console.log("resLog: ", res)
						// const logsData: any = [];
						// res.data.slice().reverse().forEach((x: any) => logsData.push({ "log": x }))
						setLogs(res.data)
						setAraciKurumLink(res.araciKurum)
						setOTPWrong(false)
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir email ve sifre giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	const onClickLogin = () => {
		getUsers()
	}

	const getUsers = () => {
		if (!(password == null || password == undefined || email == null || email == undefined)) {
			let url = `${links.getUsers}`
			ApiBase.Post({
				place: 'HomePage - getUsers',
				url: url,
				body: {
					email: email,
					password: password
				},
				successFunction: (res: any) => {
					if (res.code == 1) {
						// let data = {
						// 	alert: { type: "success", closable: true },
						// 	message: "Basariyla kullanicilar cekildi.",
						// 	showIcon: true
						// }
						// setErrorData(data)
						setErrorData(null)
						// console.log("res: ", res)
						setUsers(res.data)
						getLogs()
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir email ve sifre giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	const handleTvUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTvUsername(event.target.value as any);
	}

	const handleUuidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUuid(event.target.value as any);
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value as any);
	}

	const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value as any);
	}

	const handleOtpCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOtpCode(event.target.value as any);
	}

	const handleSMSGonderButton = () => {
		if (!(tvUsername == null || tvUsername == undefined || uuid == null || uuid == undefined)) {
			let url = `${links.sendSMS}?tv_username=${tvUsername}`
			ApiBase.Get({
				place: 'HomePage - handleSMSGonderButton',
				url: url,
				body: {},
				successFunction: (res: any) => {
					if (res.status == "success") {
						let data = {
							alert: { type: "success", closable: true },
							message: "Basariyla SMS gonderildi.",
							showIcon: true
						}
						setErrorData(data)
						setOTPWrong(false)
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir kullanıcı adı ve uzmandata id giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	const getIslemList = () => {
		if (!(tvUsername == null || tvUsername == undefined || uuid == null || uuid == undefined || otpCode == null || otpCode == undefined)) {
			let url = `${selectedKurum == "infoyatirim" ? links.infoYatirimPortfoy : links.trivePortfoy}?tv_username=${tvUsername}&otpCode=${otpCode}`
			ApiBase.Get({
				place: 'HomePage - getIslemList',
				url: url,
				body: {},
				successFunction: (res: any) => {
					if (res.status == "success") {
						setOTPWrong(false)
						const summaryData: any = {};
						for (const entry of res.data.viop_gerceklesen_emirler) {
							const sozlesme = entry["SOZLESME"];
							const alisMiktar = entry["ALIS_MIKTAR"];
							const satisMiktar = entry["SATIS_MIKTAR"];
							if (summaryData[sozlesme]) {
								summaryData[sozlesme]["ALIS_MIKTAR"] += alisMiktar;
								summaryData[sozlesme]["SATIS_MIKTAR"] += satisMiktar;
							} else {
								summaryData[sozlesme] = {
									SOZLESME: sozlesme,
									ALIS_MIKTAR: alisMiktar,
									SATIS_MIKTAR: satisMiktar,
								};
							}
						}
						const summaryArray = Object.values(summaryData);
						let lastObjectOfAll = {
							"hisse_portfoy": res.data.hisse_portfoy,
							"hisse_gerceklesen_emirler": res.data.hisse_gerceklesen_emirler,
							"viop_gerceklesen_emirler": summaryArray,
							"viop_portfoy": res.data.viop_portfoy
						}
						setIslemList(lastObjectOfAll)
						setErrorData(null)
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
						if (res.message == "OTP code is wrong.") {
							setOTPWrong(true)
						}
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir kullanıcı adı, uzmandata id ve kod giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	const getHisseMenkulList = () => {
		if (!(tvUsername == null || tvUsername == undefined || uuid == null || uuid == undefined || otpCode == null || otpCode == undefined)) {
			let url = `${selectedKurum == "infoyatirim" ? links.infoYatirimHisseMenkulList : links.triveHisseMenkulList}?tv_username=${tvUsername}&otpCode=${otpCode}`
			ApiBase.Get({
				place: 'HomePage - getHisseMenkulList',
				url: url,
				body: {},
				successFunction: (res: any) => {
					if (res.status == "success") {
						setOTPWrong(false)
						setHisseMenkulList(res.data)
						setErrorData(null)
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
						if (res.message == "OTP code is wrong.") {
							setOTPWrong(true)
						}
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir kullanıcı adı, uzmandata id ve kod giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	const getOverall = () => {
		if (!(tvUsername == null || tvUsername == undefined || uuid == null || uuid == undefined || otpCode == null || otpCode == undefined)) {
			let url = `${selectedKurum == "infoyatirim" ? links.infoYatirimOverall : links.triveOverall}?tv_username=${tvUsername}&otpCode=${otpCode}`
			ApiBase.Get({
				place: 'HomePage - getOverall',
				url: url,
				body: {},
				successFunction: (res: any) => {
					if (res.status == "success") {
						setOTPWrong(false)
						let overallDataTL: any = []
						let overallDataUSD: any = []
						res.data.doviz_cari.map((item: any, index: number) => {
							let object = {
								"id": index,
								"value": item.BAKIYE,
								"label": "Cari - " + item.TANIM,
							}
							overallDataUSD.push(object)
						})
						res.data.doviz_viop.map((item: any, index: number) => {
							let object = {
								"id": index,
								"value": item.BAKIYE,
								"label": "VİOP - " + item.TANIM,
							}
							overallDataUSD.push(object)
						})
						res.data.nakit_viop.map((item: any, index: number) => {
							let object = {
								"id": index,
								"value": item.BAKIYE,
								"label": "VİOP - " + item.TANIM,
							}
							overallDataTL.push(object)
						})
						res.data.nakit_cari.map((item: any, index: number) => {
							let object = {
								"id": index,
								"value": item.NET_BAKIYE,
								"label": "Cari - TL",
							}
							overallDataTL.push(object)
						})
						setErrorData(null)
						setOverallTL(overallDataTL)
						setOverallUSD(overallDataUSD)
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
						if (res.message == "OTP code is wrong.") {
							setOTPWrong(true)
						}
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir kullanıcı adı, uzmandata id ve kod giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	const openPosition = (hisse: string, lot: number, direction: string) => {
		if (!(tvUsername == null || tvUsername == undefined || uuid == null || uuid == undefined || otpCode == null || otpCode == undefined)) {
			let url = `${links.openPosition}`
			ApiBase.Post({
				place: 'AlSatForm - openPosition',
				url: url,
				body: {
					"tradingview_username": tvUsername,
					"uuid": uuid,
					"exchange": selectedKurum,
					"price": "10",
					"ticker": hisse,
					"isFuture": hisse.includes("F_") ? "true" : "false",
					"order_action": direction,
					"order_contracts": lot
				},
				successFunction: (res: any) => {
					if (res.code == "success") {
						setOTPWrong(true)
						let data = {
							alert: { type: "success", closable: true },
							message: "İstek gönderildi.",
							showIcon: true
						}
						// getIslemList()
						setErrorData(data)
					}
					else {
						let data = {
							alert: { type: "warning", closable: true },
							message: res.message,
							showIcon: true
						}
						setErrorData(data)
						if (res.message == "OTP code is wrong.") {
							setOTPWrong(true)
						}
					}
				},
				errorFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				},
				exceptionFunction: (res: any) => {
					let data = {
						alert: { type: "warning", closable: true },
						message: "Error! Contact with destek@uzmandata.com",
						showIcon: true
					}
					setErrorData(data)
				}
			});
		}
		else {
			let data = {
				alert: { type: "warning", closable: true },
				message: "Hata! Geçerli bir kullanıcı adı, uzmandata id ve kod giriniz!",
				showIcon: true
			}
			setErrorData(data)
		}
	}

	return (
		<Box sx={{ flexGrow: 1 }} padding={10}>
			<Grid container spacing={2} mt={5}>
				{errorData && <Grid xs={24} sm={24} md={24} lg={24} xl={24} component={Box} style={{ marginTop: 20, marginBottom: 20 }}>
					<Item>
						<AlertTemplate data={errorData} closeTime={(close: boolean) => close && setErrorData(null)} />
					</Item>
				</Grid>}
				<Grid xs={24} sm={24} md={24} lg={24} xl={24} component={Box}>
					<Item>
						<TextField
							type="text"
							label="Email"
							variant="outlined"
							fullWidth
							value={email}
							onChange={handleEmailChange}
						/>
					</Item>
					<Item>
						<TextField
							type="password"
							label="Sifre"
							variant="outlined"
							fullWidth
							value={password}
							onChange={handlePasswordChange}
						/>
					</Item>
					<Item>
						<Button
							type="submit"
							variant="contained"
							color="info"
							onClick={onClickLogin}
							style={{ marginTop: 10, marginBottom: 10 }}
						>
							GİRİŞ YAP
						</Button>
					</Item>
				</Grid>
				<Grid xs={24} sm={12} md={8} lg={24} xl={24} component={Box}>
					<Item>
						{/* <Typography color="white" fontWeight="bold">
							Uzmandata Sistem Kayıtları
						</Typography> */}
						{users && <MusterilerSelectBox onChange={(state: boolean, data: any) => state && setChoosenUser(data)} userId={choosenUser} users={users} />}
						{araciKurumLink != null &&
							<Button variant="contained" color={"success"} onClick={() => window.open(araciKurumLink, "_blank")}>
								{araciKurumLink.includes("trive") ? "Trive Yatirim" : (araciKurumLink.includes("info") ? "Info Yatirim" : (araciKurumLink.includes("deniz") ? "Deniz Yatirim" : ""))}
							</Button>
						}
						{/* <Button variant="outlined" color="error">
							Error
						</Button> */}
						<br></br>
						<Button
							type="submit"
							variant="contained"
							color="info"
							onClick={getLogs}
							style={{ marginTop: 10, marginBottom: 10 }}
						>
							LOGLARI GÜNCELLE
						</Button>
						<UzmandataLogTable logs={logs} />
					</Item>
				</Grid>
			</Grid>
		</Box>
	);
};

export default HomePage;
