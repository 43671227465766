import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import SigninPage from "./components/SigninPage";
import TitleBox from "./components/TitleBox";
import MainLayout from "./layouts/MainLayout";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import HomePage from "./components/HomePage";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
        {/* <Route path="/login" element={<MainLayout><SigninPage /></MainLayout>} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
