import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function MusterilerSelectBox({ onChange, userId, users }: any) {

    const handleChange = (event: SelectChangeEvent) => {
        onChange(true, event.target.value)
    };

    return (
        <Box sx={{ minWidth: 120, marginTop: 1, marginBottom: 1 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Hangi müşteriyi görüntülemek istersiniz?</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userId}
                    label="Hangi müşteriyi görüntülemek istersiniz?"
                    onChange={handleChange}
                >
                    <MenuItem value={0}>Tümü</MenuItem>
                    {users.map((user:any) => (<MenuItem key={user.id} value={user.id}>{user.first_name + " " + user.last_name}</MenuItem>))}
                </Select>
            </FormControl>
        </Box>
    );
}