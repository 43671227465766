import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

export default function UzmandataLogTable({ logs }: any) {
    const columns: GridColDef[] = [
        {
            field: 'created_at',
            headerName: 'Tarih',
            flex: 0.1,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                // console.log('params: ', params)
                let value : any = params.formattedValue
                return (
                    <span>{value.replace("T", " ").split(".")[0]}</span>
                )
            }
        },
        {
            field: 'log',
            headerName: 'Log',
            flex: 1,
            sortable: false,
        },
    ];


    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={logs}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                getRowId={(row: any) => Math.floor(Math.random() * (1000 - 0 + 1)) + 0}
                pageSizeOptions={[5]}
                checkboxSelection={false}
                disableRowSelectionOnClick={false}
            />
        </Box>
    );
}